import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import eu from "../images/partners/eu@2x.png"
import kunsthalloslo from "../images/partners/kunsthalloslo@2x.png"
import are from "../images/partners/are.png"
import reading from "../images/partners/reading@2x.png"

// const partners = [
//   [eu, "https://ec.europa.eu/programmes/creative-europe/"],
//   [kunsthalloslo, "http://kunsthalloslo.no/"],
//   [are, "http://are-events.org/"],
//   [reading, "http://www.reading.ac.uk/"],
// ]

class ExhibitionPage extends React.Component {
  render() {
    return (
      <Layout color="black" background="#89C190" classNames="page page--about">
        <SEO
          title="Exhibition"
          pathname={this.props.location.pathname}
          description="The Oslo dimension of EuroNoize manifests as a gallery-scale video jukebox, with a 5 metre screen, a 2K sound system and a diverse, deep but hand-picked selection of rare films, home movies, music clips and artists’ video, from Derek Jarman to Mutamassik; from Los Inciados to Rachel Maclean; from Holy Toy to Maoupa Mazzocchetti."
        />
        <div>
          <div className="container p-4">
            <div className="flex justify-center flex-wrap">
              <div className="w-full md:w-1/2 md:pr-8">
                <h2 className="mb-1">Euronoize Exhibition</h2>
                <div className="font-sans uppercase">
                  <p>
                    14th September — 27th October 2019
                    <br />
                    <a
                      className="link-black"
                      href="http://kunsthalloslo.no/"
                      target="_blank"
                    >
                      Kunsthall Oslo
                    </a>
                    <br />
                    Rostockgata 2-4
                    <br />
                    0191 Oslo
                    <br />
                  </p>
                </div>
                <div className="md:text-xs">
                  <p>
                    The Oslo dimension of EuroNoize manifests as a gallery-scale
                    video jukebox, with a 5 metre screen, a 2K sound system and
                    a diverse, deep but hand-picked selection of rare films,
                    home movies, music clips and artists’ video, from Derek
                    Jarman to Mutamassik; from Los Inciados to Rachel Maclean;
                    from Holy Toy to Maoupa Mazzocchetti.
                  </p>
                  <p>
                    EuroNoize is a project exploring the histories connecting
                    DiY music and the visual arts and the informal networks upon
                    which this cultural activity is built. The project was
                    initiated by the artists Pil and Galia Kollectiv and is
                    produced in collaboration with the University of Reading and
                    ARE, Prague. The EuroNoize screening programme was selected
                    by Pil & Galia Kollectiv, Paula Guerra, Simon Reynolds, Matt
                    Worsley, ARE and Kunsthall Oslo among many other
                    contributors.
                  </p>
                </div>
              </div>
              <div className="w-full md:w-1/2 md:pl-8 mt-8 md:mt-0">
                <div className="font-sans uppercase">
                  <p>
                    Sigrid Bendz
                    <br />
                    Sophie Lisa Beresford
                    <br />
                    Bug Kann
                    <br />
                    Henning Christiansen
                    <br />
                    Shezad Dawood
                    <br />
                    Heldon & Gilles Deleuze
                    <br />
                    Holy Toy
                    <br />
                    Derek Jarman
                    <br />
                    Ewa Justka
                    <br />
                    Paul Kindersley
                    <br />
                    Klein
                    <br />
                    Felix Kubin
                    <br />
                    Rachel Maclean
                    <br />
                    Mama Bubo
                    <br />
                    Maoupa Mazzocchetti
                    <br />
                    Lutz Mommertz
                    <br />
                    Mutamassik
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default ExhibitionPage
